import * as React from 'react';
import '../styles/global.scss';
import '../styles/normalize.scss';
import '../styles/typography.scss';
import '../styles/settings.scss';
import Headers from '../components/Headers/Headers';
import SiteInfoServices from '../components/SiteInfoServices/SiteInfoServices';
import { strony_lms_txt } from '../consts/texts';
import ServicesInfo from '../components/ServicesInfo/ServicesInfo';
import Prices from '../components/Prices/Prices';
import Footer from '../components/Footer/Footer';
import RootLayout from '../components/RootLayout/RootLayout';
import ScroolUpButton from '../components/ScroolUpButton/ScroolUpButton';
import ScroolToPriceButton from '../components/ScroolToPriceButton/ScroolToPriceButton';

const StronyLMS = () => {
  const title_top = 'LMS Websites';
  const title_middle = 'Modern Educational';
  const title_bottom = 'Platforms';
  const subtitle =
    'I create tailored LMS platforms for managing courses, students, and educational materials – ideal for schools, training companies, and teachers.';

  const siteTitle = 'LMS Websites – Modern Online Educational Platforms';
  const siteDescription =
    'I develop LMS (Learning Management System) websites that enable online courses, student management, and educational materials handling. The ideal solution for training companies and educational institutions.';
  const siteUrl = 'https://www.nowoczesnawitryna.com/lms-websites/';
  const siteUrlOtherLang = 'https://www.nowoczesnawitryna.pl/strony-lms/';

  return (
    <>
      <RootLayout title={siteTitle} url={siteUrl} urlOtherLang={siteUrlOtherLang} description={siteDescription} />
      <Headers
        title_top={title_top}
        title_middle={title_middle}
        title_bottom={title_bottom}
        subtitle={subtitle}
        active="lms"
      />
      <ScroolToPriceButton />
      <ScroolUpButton />
      <SiteInfoServices
        title_first_column={strony_lms_txt.title_first_column}
        title_first_card={strony_lms_txt.title_first_card}
        title_second_card={strony_lms_txt.title_second_card}
        descryption_first_column={strony_lms_txt.descryption_first_column}
        card_descryption={strony_lms_txt.card_descryption}
        second_card_descryption={strony_lms_txt.second_card_descryption}
      />
      <ServicesInfo wordpress />
      <Prices lms />
      <Footer />
    </>
  );
};

export default StronyLMS;
